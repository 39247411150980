import React, { useState } from 'react';

const Branch = ({ href, imageSrc, overlayImageSrc, altText, overlayAltText }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="branch-container">
      <a
        href={href}
        className="branch"
        target="_blank"
        rel="noopener noreferrer"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="img-container" style={{ position: 'relative', display: 'inline-block' }}>
          {/* Base Image */}
          <img 
            src={imageSrc} 
            alt={altText || 'Branch base image'} 
            className="branch-image" 
            style={{ display: 'block', width: '100%', height: 'auto' }}
          />

          {/* Overlay Image */}
          {isHovered && overlayImageSrc && (
            <img 
              src={overlayImageSrc} 
              alt={overlayAltText || 'Branch overlay image'} 
              className="overlay-image" 
              style={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                width: '100%', 
                height: '100%', 
                objectFit: 'cover' 
              }}
            />
          )}
        </div>
      </a>
    </div>
  );
};

export default Branch;
